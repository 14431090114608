import React from "react"
import Home from "./Home";
import SEO from "../components/seo";
import MainLayout from "../components/MainLayout";
//import WOW from "wowjs/dist/wow.js";

class IndexPage extends React.Component{
  //componentDidMount= () => new WOW.WOW().init();

  render(){
     return ( <MainLayout>
      <SEO title="Home" keywords={[`mausam`, `developer`, `portfolio`]} />
      <Home />
    </MainLayout> );

  }
}

export default IndexPage
