import React from 'react';
import Headroom from 'react-headroom';
import {Link} from 'react-scroll';
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {P_FACEBOOK} from "../../utils/Constant";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Navigation extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpen: false
        };
    }

    toggle() {
        console.log(this.state.isOpen);
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <>
                <Headroom className="wrapper-headroom-fix"
                          onUnpin={
                              () => {
                                  const element = this.myDiv;
                                  element.classList.add('fixed-top');
                              }}
                          onUnfix={
                              () => {
                                  const element = this.myDiv;
                                  element.classList.add('fixed-top');
                              }}>
                    <span className="fixed-top" ref={ref => this.myDiv = ref}>
                        <nav className="navbar navbar-expand-lg navbar-light mainNav">
                            <button className="nav-toggler" aria-label="menu" type="button"
                                    onClick={() => this.toggle()}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="container">
                                <Link className="navbar-brand nav-link" activeClass="active" to="section-header"
                                      spy={true} smooth={true} duration={500}> MAUSAM RAYAMAJHI </Link>
                                <div className={this.state.isOpen ? '' : 'hidden'} id="navbarResponsive">
                                    <ul className="navbar-nav ml-auto" style={{display: 'inline-flex'}}>
                                        <li className="nav-item">
                                            <Link className="nav-link" activeClass="active" to="section-header"
                                                  spy={true} smooth={true} duration={500}> Home </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" activeClass="active" to="section-skills"
                                                  spy={true} smooth={true} duration={500}> Skills </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" activeClass="active" to="section-projects"
                                                  spy={true} smooth={true} duration={500}> Projects </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" activeClass="active" to="section-experience"
                                                  spy={true} smooth={true} duration={500}> Experience </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" activeClass="active" to="section-contact"
                                                  spy={true} smooth={true} duration={500}> Contact </Link>
                                        </li>
                                         <li className="nav-item">
                                               <OutboundLink className="nav-link" activeClass="active"
                                                             href="https://firebasestorage.googleapis.com/v0/b/resume-22a3a.appspot.com/o/MAUSAM%20CV_J.pdf?alt=media&token=bbfcfaf5-f21a-44a4-95aa-91fefae4a553"
                                                             target="_blank" rel="noopener noreferrer">
                            Download Resume
                        </OutboundLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </span>
                </Headroom>
            </>
        );
    }
}

export default Navigation;