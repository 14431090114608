import React from 'react';
import ContactCircle from './ContactCircle';

const Footer = () => {
    return (
        <footer className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="wow slideInDown">Mausam Rayamajhi</h2>
                    <ContactCircle />
                </div>
                <div className="col-md-12 mt-3 footer-copyright">
                    <span className="copyright">Copyright &copy; Mausam Rayamajhi 2019</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;